import React from "react";
import Header from "./header.js";
import TopNav from "./topnav.js";
import Box from '@mui/material/Box';
import RightPanel from "../components/rightpanel";
import {  ThemeProvider } from '@mui/material/styles';
import Footer from "./footer.js";
import theme from "./theme.js"


const Page = ({location, pageTitle, children}) => {
    const drawerWidth = 180;
   
    return (
      <ThemeProvider theme={theme}>
      <Header  location={location} pageTitle={pageTitle}/>
      
      <Box sx={{ display: 'flex' }}>
        <TopNav drawerWidth={drawerWidth} />
        
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { sm: `calc(100% - ${drawerWidth}px)` } }} >
          {children}
          <RightPanel/>
        </Box>

        <Footer  drawerWidth={drawerWidth} />
      </Box>
      

    </ThemeProvider>);

};

export default Page;